@import 'styles/main.scss';

.container {
  padding: 0 0 2.25rem 0;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: $montserrat;
}
