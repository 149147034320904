@import 'styles/main.scss';

.header {
  width: 100%;
  background-color: $basicGreen;
  min-height: 3.5rem;
  padding: 0 1.4375rem 0 0.875rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  &__inner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.button__burger {
  color: $white !important;
}

.button__user {
  color: $white !important;
}
