@import 'styles/main.scss';

.container {
  max-width: 41.375rem;
  margin-top: 2.75rem;
}

.button__add {
  color: $white !important;
}

.heading__box {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.text__inactive {
  color: rgba(0, 0, 0, 0.3);
}
