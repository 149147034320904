@import 'styles/main.scss';

.input__group {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
}

.input__container {
  max-width: 25.125rem;
  display: flex;
  position: relative;
}

.prompt__button__wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -3.125rem;
}

.button__save {
  color: $white !important;
}

.button__group {
  display: flex;
  align-items: center;
  gap: 0.8125rem;
  margin-top: 2.4375rem;
}
