@import 'styles/main.scss';

.content {
  padding: 2rem;
  margin: 12.5rem auto 0 auto;
  width: 25.625rem;
}

.loader__container {
  padding-top: 12.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  font-family: $montserrat;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 600;
  margin-bottom: 1.3125rem;
  text-align: center;
}
