.img__wrapper {
  width: 10rem;
  height: 10rem;
  border-radius: 0.5rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.img__wrapper:hover .cover {
  opacity: 1;
}
