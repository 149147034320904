@media (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1550px) {
  html {
    font-size: 15.5px;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1450px) {
  html {
    font-size: 14.5px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1350px) {
  html {
    font-size: 13.5px;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1250px) {
  html {
    font-size: 12.5px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
}
