@import 'styles/main.scss';

.button__dropdown {
  color: $white !important;
}

.container {
  position: relative;
}

.pooper {
  width: 100%;
}
