@import 'styles/main.scss';

.form {
  &__container {
    width: 100%;
    display: flex;
    margin-top: 1.3125rem;
    justify-content: space-between;
  }

  &__left {
    flex: 0 1 200px;
    margin-right: 1.3125rem;
  }

  &__right {
    flex: 1 1 auto;
  }
}

.input__wrapper {
  margin-bottom: 1.3125rem;
}

.image__error {
  color: #d32f2f;
  font-size: 0.75rem;
  margin: 3px 0.875rem;
}

.button {
  &__save {
    color: $white !important;
    width: 136px;
  }

  &__reset {
    width: 136px;
  }

  &__upload {
    margin-top: 5px;
    text-transform: none;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.button__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.875rem;
  margin-top: 2.3125rem;
}
