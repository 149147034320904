.container {
  max-width: 350px;
  margin-top: 2.75rem;
}

.title {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.heading {
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

.form__container {
  padding: 2rem 2rem 3rem 2rem;
}

.button__save {
  margin-top: 20px !important;
  color: #fff !important;
}

.button__prompt__wrapper {
  margin-left: 1rem;
}
