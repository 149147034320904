@import 'styles/main.scss';

.container {
  padding: 2.25rem 0 3.3125rem 0;
}

.button__new__ad {
  color: #fff !important;
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}
