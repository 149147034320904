@import 'styles/main.scss';

.container {
  padding: 0 0 2rem 0;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.75rem;
}

.title {
  font-family: $montserrat;
}

.filter__row {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    gap: 1.125rem;
    align-items: center;
  }

  &__right {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.reset__row {
  padding-top: 1.5rem;
  display: flex;
  gap: 1.125rem;
  align-items: center;
}

.button__reset {
  line-height: 24px;
}
