@import 'styles/main.scss';

.container {
  margin-top: 2.5rem;
  width: fit-content;
  padding: 2rem 5rem 2rem 3rem;
}

.title {
  font-family: $montserrat;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.preview {
  width: 250px;
  height: auto;
  margin-bottom: 5px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.file {
  position: relative;
}

.prompt__button__wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -3.75rem;
}

.input__container {
  max-width: 250px;
  margin-top: 2rem;
  position: relative;
}

.button__upload {
  width: 250px;
}

.button__delete {
  width: 250px;
  color: red !important;
  margin-bottom: 0.625rem !important;
}

.button__save {
  margin-top: 1rem !important;
  color: $white !important;
}
