@import 'styles/main.scss';

.backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  background-color: $white;
  width: 600px;
  min-height: 466px;
  padding: 1.5rem 6.25rem 1.5rem 1.5rem;
  border-radius: 0.5rem;
  margin: 1.25rem 0;
}

.title {
  font-family: $montserrat;
  font-size: 1.5rem;
  line-height: 1.6875rem;
  font-weight: 600;
}
