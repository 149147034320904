@import 'styles/main.scss';

.form__wrapper {
  max-width: 60rem;
}

.box {
  display: flex;
  gap: 2.3125rem;
}

.box__right {
  flex: 1;
}

.main__image {
  width: 17rem;
  height: 17rem;
  border-radius: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
}

.author {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__title {
    margin-bottom: 0.5rem;
  }
}

.button__group {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.switcher__box {
  width: 47%;
  height: 53px;
  border-radius: 4px;
  border: 1px solid $basicGreen;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file__box {
  padding: 1rem;
  margin-top: 1.125rem;
}

.file__box__title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-family: $montserrat;
  margin-bottom: 1.5625rem;
}

.button__add__file {
  margin-top: 1rem !important;
  color: $black !important;
  font-family: $montserrat !important;
}

.file__list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: fit-content;
}

.file__list__item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.file__title {
  font-weight: 600;
  margin-right: 5rem;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.file__title__link {
  color: #0066ff;
  font-weight: 600;
  margin-right: 5rem;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.photo__list {
  display: flex;
  gap: 1.5625rem;
  justify-content: flex-end;
  margin: 1.5625rem 0 0 1.1875rem;
  flex-wrap: wrap;
}

.button__submit__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
}
