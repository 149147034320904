.container {
  max-width: 37.5rem;
  margin-top: 2.5rem;
}

.paper {
  padding: 2.0625rem 1.625rem 3.4375rem 1.625rem;
}

.input__container {
  margin-bottom: 1rem;
}

.button__save {
  color: #fff !important;
}
