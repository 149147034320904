@import 'styles/main.scss';

.content {
  width: 19.0625rem;
}

.title {
  font-family: $montserrat;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 600;
  text-align: center;
}

.button__save {
  color: $white !important;
}

.button__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.875rem;
  margin-top: 2.3125rem;
}
