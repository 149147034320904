.no_rows {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.6875rem;
    padding-bottom: 2.6875rem;
  }
  &__icon {
    font-size: 3rem !important;
  }

  &__text {
    margin-top: 1.0625rem;
    font-size: 0.875rem;
  }
}
