@import 'styles/main.scss';

.form__container {
  max-width: 41.3125rem;
  margin-top: 0.625rem;
}

.input {
  background-color: $white;
}

.input__inner {
  width: 100%;
}

.input__error {
  color: #d32f2f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.input__wrapper {
  margin-top: 2.4375rem;
  display: flex;
}

.input__title {
  margin-bottom: 0.6875rem;
}

.button__save {
  color: $white !important;
  margin-top: 1rem !important;
}
