@import 'styles/main.scss';

.img__wrapper {
  width: 10.5625rem;
  height: 10.5625rem;
  border-radius: 0.5rem;
  background-color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}
