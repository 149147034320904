@import 'styles/main.scss';

.heading {
  margin-top: 1.8125rem;
  margin-bottom: 2.375rem;
  display: flex;
  justify-content: space-between;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: $montserrat;
    margin-right: 0.5rem;
  }
}

.button__add {
  color: $white !important;
}

.cell__link {
  display: flex;
  align-items: center;

  p {
    max-width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cell__buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.cell__description {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell__link {
  color: #2714ff;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
