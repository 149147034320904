@import 'styles/main.scss';

.container {
  padding: 2.25rem 0 3.3125rem 0;
}

.title {
  font-size: 2.25rem;
  font-weight: 600;
  font-family: $montserrat;
  margin-bottom: 2.25rem;
}
