@import 'media';
@import 'mixins';
@import 'root';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  cursor: default;
  @extend %scrollbar;
  font-family: $roboto;
  font-size: 1rem;
  width: 100%;
}

a {
  display: inline-block;
  text-decoration: none;
}

// убирает цвет автокомплита у инпута
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-size: 1rem;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

textarea,
button,
input {
  // font-family: $roboto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  cursor: pointer;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
