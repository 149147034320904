@import 'styles/main.scss';

.content {
  width: 19.0625rem;
}

.title {
  font-family: $montserrat;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 600;
}
