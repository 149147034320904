@import 'styles/main.scss';

.loader {
  &__box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  width: 4.125rem;
  height: 4.125rem;

  img {
    width: 100%;
  }
}
