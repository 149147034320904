.container {
  width: 500px;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6875rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.text {
  line-height: 1.5rem;
}

.screens {
  margin-top: 2.25rem;
}

.screen__container {
  width: 300px;

  img {
    width: 100%;
  }
}
