.container {
  width: 600px;
  max-height: 90vh;
  overflow: auto;
  margin: 0 auto;
  padding-right: 1.5rem;
  padding-left: 1rem;

  &::-webkit-scrollbar {
    width: 0.5%;
    min-width: 6px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 2rem;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 3px;
  }
}

.title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6875rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.text {
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
}

.screen__container {
  margin-top: 2.25rem;
  margin-bottom: 2rem;
  width: 100%;

  img {
    width: 100%;
  }
}
