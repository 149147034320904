@import 'styles/main.scss';

.page__title {
  font-size: 2.25rem;
  font-family: $montserrat;
  font-weight: 600;
  padding: 1.625rem 0 1.5rem 6px;
}

.tabpanel__container > div {
  padding: 0;
  padding-bottom: 3.125rem;
}
