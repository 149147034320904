@import 'styles/main.scss';

.container {
  margin-top: 2.5rem;
  padding-top: 1.5rem;
  // max-width: 47.5rem;
  width: fit-content;
  padding: 2rem;
}

.title {
  font-family: $montserrat;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.preview {
  width: 250px;
  height: auto;
  margin-bottom: 5px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.preview__empty {
  // box-shadow: 0px 0px 8px 0px rgba(8, 9, 9, 0.5);
}

.input__container {
  max-width: 250px;
  margin-top: 2rem;
}

.button__upload {
  width: 250px;
}
.button__save {
  margin-top: 1rem !important;
  color: $white !important;
}
