@import 'styles/main.scss';

.input__container {
  max-width: 25.125rem;
  display: flex;
  margin-top: 1.3125rem;
}

.button__save {
  color: $white !important;
}

.button__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.875rem;
  margin-top: 2.3125rem;
}
