//Создание переменных
$black: #000;
$white: #fff;
$basicGreen: #1cc700;
$lightGreen: #ebf8e9;
$basicGray: #e9e9e9;
$basicLink: #3c2fad;

$montserrat: 'Montserrat', sans-serif;
$roboto: 'Roboto', sans-serif;
