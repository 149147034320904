@import 'styles/main.scss';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 18.5rem;
  min-height: 100vh;
  background-color: $white;
  padding-top: 3.5rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;

  &__inner {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
  }
}

.sidebar.active {
  transform: translateX(0);
}

.sidebar__logo {
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  border-bottom: 1px solid #d8d8d8;
}

.menu {
  &__item__link {
    width: 100%;
  }

  &__item {
    width: 100%;
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }

  &__item.active {
    background-color: $lightGreen;
  }

  &__item__text {
    font-size: 1rem;
    margin-left: 0.5rem;
    line-height: 1.5rem;
    color: $black;
    font-weight: 500;
  }

  &__item__text__disabled {
    margin-left: 0.5rem;
    color: rgba(175, 175, 175, 0.8);
  }
}

.accordion__trigger__heading {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
}

.accordion__trigger__text {
  margin-left: 0.5rem;
  line-height: 1.5rem;
  color: $black;
  font-weight: 500;
}

.accordion {
  &__trigger {
    position: relative;
    transition: border 0.3s ease-in;

    &_open {
      border-bottom: 1px solid transparent;
    }

    &::before,
    &::after {
      content: '';
      width: 2px;
      height: 0.5rem;
      position: absolute;
      top: 45%;
      transition: transform 0.3s ease-in;
      background-color: gray;
    }

    &::before {
      transform: rotate(45deg);
      right: 2.5rem;
    }

    &::after {
      transform: rotate(-45deg);
      right: 2.2rem;
    }

    &_open {
      &::before {
        transform: rotate(135deg);
      }

      &::after {
        transform: rotate(-135deg);
      }
    }
  }
}

.accordion__subitem {
  width: 100%;
  min-height: 3rem;
  line-height: 1.5rem;
  padding-left: 3.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  &.active {
    background-color: $lightGreen;
  }

  a {
    color: $black;
    display: inline-block;
  }
}

.bottom {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 1.75rem;

  &__text {
    font-size: 0.875rem;
    font-weight: 600;
    max-width: 12.4375rem;
  }

  &__link {
    margin-top: 0.625rem;
    display: flex;
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: fit-content;
  }

  &__link p {
    color: $black;
    font-size: 0.875rem;
    font-weight: 600;
    font-family: $montserrat;
    margin-left: 0.5rem;
  }

  &__link__svg {
    flex-shrink: 0;
  }
}
