@import 'styles/main.scss';

.cell__title {
  cursor: pointer;
}

.row {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.cell {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    padding-top: 0.5rem;
    padding-left: 3px;
  }
}

.user__link,
.user__link:active {
  color: $basicLink;
}
