@import 'styles/main.scss';

.logo {
  &__wrapper {
    display: flex;
    align-items: flex-end;
  }

  &__icon {
    position: relative;
    top: 2px;
    margin-right: 0.625rem;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $black;
    font-weight: 800;
    font-family: $montserrat;
  }

  &__text__green {
    color: $basicGreen;
  }
}
