@import 'styles/main.scss';

.page {
  background-color: $basicGray;
  min-height: calc(100vh - 3.5rem);
}

.page__wrapper {
  max-width: 1403px; // необходимо в px
  width: 100%;
  margin: 0px auto;
  padding-left: 18.625rem;
}

.page__content {
  padding: 0 1.4375rem 0 1.125rem;
  min-height: 300px;
}
