@import 'styles/main.scss';

.container {
  max-width: 47.5rem;
  padding-top: 2.5rem;
}

.paper {
  padding: 2.0625rem 1.625rem 3.4375rem 1.625rem;
}
