@import 'styles/main.scss';

.user__link,
.user__link:active {
  color: $basicLink;
  margin-bottom: 0.5rem;
}

.box_number {
  padding-left: 2rem;
}

.row {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
